import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'Beautiful UI',
    description:
      'Close more loans with a beautiful UI that makes it easy for clients to understand and use.',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'Data Visualizations',
    description:
      'Our custom data visualizations make it easier for your clients to understand their loan options.',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'Novices & Experts',
    description:
      'We balance simplicity with power, so both novices and experts can use our product.',
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'Mobile Technology',
    description:
      'Email and PDFs are a thing of the past. Our mobile app allows your clients to view and interact with their loan documents on the go.',
  },
  {
    icon: <SupportAgentRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'A.I. Integration',
    description:
      'Use the latest LLMs from OpenAI to answer client loan questions and more.',
  },
  {
    icon: <QueryStatsRoundedIcon sx={{ fontSize: 50 }} />,
    title: 'Close More Loans',
    description:
      'When loans are presented on a digitally crafted platform, they close more easily.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Highlights
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out from the crowd and how we can help you do the same.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium', fontSize: 20 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
