import * as React from 'react';
import { PaletteMode, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
// import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
// import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getMPTheme from './theme/getMPTheme';
import { Typography } from '@mui/material';


export default function MarketingPage() {
  const [mode, setMode] = React.useState<PaletteMode>('light');
  const MPTheme = createTheme(getMPTheme(mode));

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem('themeMode') as PaletteMode | null;
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)',
      ).matches;
      setMode(systemPrefersDark ? 'dark' : 'light');
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode); // Save the selected mode to localStorage
  };


  return (
      <ThemeProvider theme={MPTheme}>
        <CssBaseline enableColorScheme />

        <AppAppBar />
        <Hero />
        <div>
          {/* <LogoCollection /> */}
          <Features />
          <Divider />
          <FlamingoMeaning />
          {/* <Testimonials /> */}
          <Divider />
          <Highlights />
          <Divider />
          <Pricing />
          <Divider />
          <FAQ />
          <Divider />
          <Footer mode={mode} toggleColorMode={toggleColorMode} />
        </div>
      </ThemeProvider>
  );
}

function FlamingoMeaning() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Flamingo
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <Grid
          size={{ xs: 12, sm: 12, md: 6, lg: 6 }}
        >
          <Typography
          component="h4"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Beauty
        </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            We take pride in the beauty of our app. Your client's will see the your quality and the convenience of Flamingo the first time they interact with it. 

          </Typography>
        </Grid>
          <Grid
            size={{ xs: 12, sm: 12, md: 6, lg: 6 }}
          >
            <Typography
              component="h4"
              variant="h4"
              gutterBottom
              sx={{ color: 'text.primary' }}
            >
              Balance
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Flamingo helps you automate the balance between simplicity and complexity. Knowing when to present simplicity over complexity is an art - one that we've mastered.
            </Typography>
          </Grid>
      </Grid>
    </Container>
  )
}